import style from './FourthSection.module.scss'

const FourthSection = () => 
<section className={style.main}>
  <div className={style.container}>
    <div className={style.image_container}>
      <div className={style.image}></div>
    </div>
    <div className={style.description_container}>
      <div className={style.description_icon}></div>
      <h2 className={style.title}>We are developing our own NFT game, where SUFO will be the main and most useful resource</h2>
      <p className={style.description_text}>The UFO Money team is working on their own NFT services. One of these services will be an open-world multiplayer game with the following features: traveling through space; exploring and discovering planets and stars; terraforming planets; buying, selling, renting and leasing territories on planets, entire planets, stars or entire galaxies; developing settlements and much more! For the convenience of exchange, mankind invented money. The analogue of money in the game from UFO Money are SUFO tokens.</p>
      <div>
        <a className={style.description_link_buy} href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2E427a0EbeAf2656A23FC289b12CE48f9CC3Fb7A">BUY SUFO</a>
      </div>
    </div>
  </div>
</section>

export default FourthSection;