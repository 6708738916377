import style from './SixthSection.module.scss'

const SixthSection = () => 
<section className={style.main}>
  <div className={style.container}>
    <div>
      <h3 className={style.subtitle}>Our future plans</h3>
      <h2 className={style.title}>Roadmap</h2>
   </div>
   <div className={style.wrap}>
     <ul className={style.list}>
       <div className={style.list_wrap}> 
        <li className={`${style.list_item} ${style.bg_blue}`}>
          <h3 className={style.phase_title}>Phase 1</h3>
          <ul className={style.phase_list}>
            <li className={`${style.phase_item} ${style.phase_item_done}`}>Functioning website</li>
            <li className={`${style.phase_item} ${style.phase_item_done}`}>Social media pages</li>
            <li className={`${style.phase_item} ${style.phase_item_done}`}>Token creation</li>
            <li className={`${style.phase_item} ${style.phase_item_undone}`}>Listing on PancakeSwap</li>
          </ul>
        </li>
        <div className={style.line_wrap}>
         <div className={`${style.line_point} ${style.bg_blue} ${style.border_blue}`}></div>
         <div className={`${style.line} ${style.bg_blue}`}></div>
        </div>
       </div>
       <div className={style.list_wrap}> 
          <li className={`${style.list_item} ${style.bg_darkblue}`}>
            <h3 className={style.phase_title}>Phase 2</h3>
            <ul className={style.phase_list}>
              <li className={`${style.phase_item} ${style.phase_item_undone}`}>Apply to add a logo to PancakeSwap</li>
              <li className={`${style.phase_item} ${style.phase_item_undone}`}>Apply for CoinMarketCap & CoinGecko</li>
            </ul>
          </li>
          <div className={style.line_wrap}>
            <div className={`${style.line_point} ${style.bg_darkblue} ${style.border_darkblue}`}></div>
            <div className={`${style.line} ${style.bg_darkblue}`}></div>
          </div>
        </div>
        <div className={style.list_wrap}> 
          <li className={`${style.list_item} ${style.bg_violet}`}>
            <h3 className={style.phase_title}>Phase 3</h3>
            <ul className={style.phase_list}>
              <li className={`${style.phase_item} ${style.phase_item_undone}`}>Partnerships</li>
              <li className={`${style.phase_item} ${style.phase_item_undone}`}>Relationships with Youtubers & Influencers</li>
              <li className={`${style.phase_item} ${style.phase_item_undone}`}>Listings on various exchanges</li>
            </ul>
          </li>
          <div className={style.line_wrap}>
            <div className={`${style.line_point} ${style.bg_violet} ${style.border_violet}`}></div>
            <div className={`${style.line} ${style.bg_violet}`}></div>
          </div>
        </div>
        <div className={style.list_wrap}> 
        <li className={`${style.list_item} ${style.bg_red}`}>
          <h3 className={style.phase_title}>Phase 4</h3>
          <ul className={style.phase_list}>
            <li className={`${style.phase_item} ${style.phase_item_undone}`}>Launching an NFT game</li>
            <li className={`${style.phase_item} ${style.phase_item_undone}`}>Integration of SUFO token into the game by UFO Money</li>
            <li className={`${style.phase_item} ${style.phase_item_undone}`}>Bridge for NFT</li>
          </ul>
          </li>
          <div className={style.line_wrap}>
            <div className={`${style.line_point} ${style.bg_red} ${style.border_red}`}></div>
          </div>
        </div>
     </ul>
   </div>
  </div>
  
</section>

export default SixthSection;