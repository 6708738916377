import style from './FirstSection.module.scss'
import Header from '../Header/Header'
import InfoItem from '../InfoItem/InfoItem'

const FirstSection = () => 
  <section className={style.first_section}>
    <div className={style.container}>
      <Header/>
      <div className={style.title_container}>
        <h1 className={style.title}>
        SpaceXFloki - a token that rewards you in BUSD!
        </h1>
      </div>
      <InfoItem numberOfSection={1}/>
      
    </div>
  </section>

export default FirstSection 