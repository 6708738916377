import style from './SecondSection.module.scss'
import ItemInfo from '../InfoItem/InfoItem'

const SecondSection = () => 
<section className={style.main}>
  <div className={style.info_container}>
    <h3 className={style.subtitle}>What our token does</h3>
    <h2 className={style.title}>Tokenomics</h2>
    <h3 className={style.description}>999 septillion tokens</h3>
  </div>
  <ItemInfo numberOfSection={2}/>
</section>

export default SecondSection;