import style from './InfoItem.module.scss'

const InfoItem = ({numberOfSection}) => 

  <div>
    {numberOfSection === 1 ? <div className={style.info_wrap}><div className={style.info_item}>
      <div className={style.info_icon_1}></div>
      <div className={style.info_title}>
        Share
      </div>
      <p className={style.info_text}>
        Share, repost, retweet on social media
      </p>
    </div>
    <div className={style.info_item}>
      <div className={style.info_icon_2}></div>
      <div className={style.info_title}>
        Buy
      </div>
      <p className={style.info_text}>
        Buy $SUFO via PancakeSwap
      </p>
    </div>
    <div className={style.info_item}>
      <div className={style.info_icon_3}></div>
      <div className={style.info_title}>
      Hold
      </div>
      <p className={style.info_text}>
      5% of every transaction will be distributed to all holders
      </p>
    </div>
    <div className={style.info_item}>
      <div className={style.info_icon_4}></div>
      <div className={style.info_title}>
      Improve DeFi
      </div>
      <p className={style.info_text}>
      Get SUFO and help develop the UFO Money ecosystem!
      </p>
    </div> </div>: '' }
    {numberOfSection === 2 ? 
    <div className={style.info_wrap_second}>
      <div className={style.info_item_second}>
        <div className={style.info_icon_second}></div>
        <h3 className={style.info_title_second}>10%</h3>
        <p className={style.info_text_second}>Max tax per transaction</p>
      </div>
      <div className={style.info_item_second}>
        <div className={style.info_icon_second}></div>
        <h3 className={style.info_title_second}>5%</h3>
        <p className={style.info_text_second}>Redistribution to holders</p>
      </div>
      <div className={style.info_item_second}>
        <div className={style.info_icon_second}></div>
        <h3 className={style.info_title_second}>100%</h3>
        <p className={style.info_text_second}>Liquidity locked for 1 year</p>
      </div>
      <div className={style.info_item_second}>
        <div className={style.info_icon_second}></div>
        <h3 className={style.info_title_second}>5%</h3>
        <p className={style.info_text_second}>Goes to increase liquidity</p>
      </div>
      <div className={style.info_item_second}>
        <div className={style.info_icon_second}></div>
        <h3 className={style.info_title_second}>10%</h3>
        <p className={style.info_text_second}>Airdrop for the community</p>
      </div>
      <div className={style.info_item_second}>
        <div className={style.info_icon_second}></div>
        <h3 className={style.info_title_second}>5%</h3>
        <p className={style.info_text_second}>Going to develop an NFT game</p>
      </div>
    </div> : ''}
    {numberOfSection === 3 ? 
    <div className={style.info_wrap_third}>
      <div className={style.info_item_third}>
        <div className={style.info_icon_third}></div>
        <h3 className={style.info_title_third}>BlackList</h3>
        <p className={style.info_text_third}>Prevent selling from bots after purchasing.</p>
      </div>
      <div className={style.info_item_third}>
        <div className={style.info_icon_third}></div>
        <h3 className={style.info_title_third}>Decoy Contracts</h3>
        <p className={style.info_text_third}>Create multiple contracts on launch.</p>
      </div>
      <div className={style.info_item_third}>
        <div className={style.info_icon_third}></div>
        <h3 className={style.info_title_third}>Time Delay</h3>
        <p className={style.info_text_third}>For the first 15 mins add a timedelay on transactions.</p>
      </div>
      <div className={style.info_item_third}>
        <div className={style.info_icon_third}></div>
        <h3 className={style.info_title_third}>Max TX Size</h3>
        <p className={style.info_text_third}>Bots can't snipe all of our tokens in one transaction.</p>
      </div>
    </div> : ''}
  </div>

export default InfoItem;