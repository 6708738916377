import style from './FifthSection.module.scss'

const FifthSection = () => 
<section className={style.main}>
  <div className={style.container}>
    <h3 className={style.subtitle}>Purchase SUFO on PancakeSwap</h3>
    <h2 className={style.title}>How To Buy</h2>
    <div className={style.wrap}>
      <div className={style.exchange_info}>
        <h3 className={style.exchange_title}>To purchase on PancakeSwap:</h3>
        <ul className={style.exchange_list}>
          <li className={style.exchange_item}>1. Install Metamask {' '} 
          <a className={style.exchange_link} href="https://metamask.io/download.html">(metamask.io) </a> 
          or use an existing wallet
          </li>
          <li className={style.exchange_item}>2. Withdraw your BNB from exchange to Metamask or to another wallet
          </li>
          <li className={style.exchange_item}>3. If you use Metamask, add a Smart Chain network using {' '}
          <a className={style.exchange_link} href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain">(this guide)</a>
          </li>
          <li className={style.exchange_item}>4. Go to PancakeSwap {' '}
          <a className={style.exchange_link} href="https://exchange.pancakeswap.finance">(exchange.pancakeswap.finance)</a>
          </li>
          <li className={style.exchange_item}>5. Click 'Unlock Wallet'</li>
          <li className={style.exchange_item}>6. Click 'Select a currency'</li>
          <li className={style.exchange_item}>7. Add the $SUFO token by pasting the Smart Contract Address - {' '}
          <span className={style.exchange_color_word}>0x2E427a0EbeAf2656A23FC289b12CE48f9CC3Fb7A</span></li>
          <li className={style.exchange_item}>8. Swap your {' '}
          <span className={style.exchange_color_word}>BNB</span> {' '}
          for {' '}
          <span className={style.exchange_color_word}>SUFO</span>!</li>
        </ul>
      </div>
      <div className={style.exchange_image}></div>
    </div>
  </div>
</section>

export default FifthSection;