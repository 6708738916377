import style from './Footer.module.scss'

const Footer = () => 
<footer className={style.main}>
  <div className={style.main_wrap}>
    <div className={style.wrap}>
      <h3 className={style.title}>Useful Links</h3>
      <div className={style.link_wrap}>
        <a className={style.link} href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2E427a0EbeAf2656A23FC289b12CE48f9CC3Fb7A">PancakeSwap</a>
        <a className={style.link} href="https://dex.guru/token/0x2E427a0EbeAf2656A23FC289b12CE48f9CC3Fb7A-bsc">DexGuru</a>   
        <a className={style.link} href="https://bscscan.com/token/0x2E427a0EbeAf2656A23FC289b12CE48f9CC3Fb7A">BscScan</a>    
        </div>
    </div>
    <div className={style.wrap}>
      <h3 className={style.title}>Social Links</h3>
      <div className={style.link_wrap}>
        <a className={style.alt_link} href="https://twitter.com/SUFOcrypto">
          <div className={style.link_icon}></div>
        </a>
        <a className={style.alt_link} href="https://t.me/SUFOcrypto">
          <div className={style.link_icon}></div>
        </a>
        <a className={style.alt_link} href="https://sufo-ufo.medium.com">
          <div className={style.link_icon}></div>
        </a>

        </div>
    </div>
  </div>
</footer>

export default Footer;