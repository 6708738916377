import style from './Header.module.scss'

const Header =() => 
  <header className={style.main}>
    <nav className={style.nav}>
      <a className={style.link} href="https://ufo.money">UFO Money</a>
      <a className={style.link} href="https://dex.guru/token/0x2E427a0EbeAf2656A23FC289b12CE48f9CC3Fb7A-bsc">Chart</a>
      <a className={style.alt_link} href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2E427a0EbeAf2656A23FC289b12CE48f9CC3Fb7A">BUY NOW</a>
    </nav>
  </header>

  export default Header;