import style from './App.module.scss'
import FirstSection from '../FirstSection/FirstSection'
import SecondSection from '../SecondSection/SecondSection'
import FourthSection from '../FourthSection/FourthSection'
import FifthSection from '../FifthSection/FifthSection'
import SixthSection from '../SixthSection/SixthSection'
import Footer from '../Footer/Footer'

function App() {
  return (
    <div className="App">
      <div className={style.mini_info}>
        <div className={style.mini_info_icon}>
          <svg className={style.mini_info_icon_svg} fill="#2196f3" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path>
          </svg>
        </div>
        <p className={style.mini_info_text}>On August 5, applications for the whitelist will stop being accepted!</p>
      </div>
      <FirstSection/>
      <SecondSection/>
      <FourthSection/>
      <FifthSection/>
      <SixthSection/>
      <Footer/>
    </div>
  )
}

export default App;
